<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="fixed bottom-0 w-2/3 lg:w-1/4"
    viewBox="0 0 70.319 11.23"
  >
    <path
      d="M61.145 175.809a1.75 1.75 0 0 0-1.661-.001l-.005.003c-.032.018-.059.041-.089.061-.386.278-.647.72-.668 1.228h3.183a1.592 1.592 0 0 0-.667-1.227c-.03-.02-.058-.044-.09-.062a.05.05 0 0 0-.003-.001m4.017 0a1.75 1.75 0 0 0-1.661-.002l-.005.003c-.031.017-.059.041-.089.061a1.59 1.59 0 0 0-.667 1.227h3.182a1.592 1.592 0 0 0-.666-1.226c-.03-.02-.059-.045-.09-.062l-.003-.001m4.017-.001a1.751 1.751 0 0 0-1.662-.001l-.005.003c-.032.018-.059.042-.089.061-.386.278-.647.72-.668 1.227h3.183a1.59 1.59 0 0 0-.667-1.226c-.03-.02-.058-.044-.09-.062a.05.05 0 0 0-.003-.001"
      style="fill:#ffdc04;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M59.39 175.872c.03-.02.057-.044.088-.061l.005-.003a1.751 1.751 0 0 1 1.665.002c.031.018.06.042.09.062.371.244.643.65.666 1.227h.835c.024-.578.296-.984.668-1.228.03-.02.057-.043.089-.06 0-.002.003-.003.005-.004a1.752 1.752 0 0 1 1.663.002c.032.018.06.043.091.063.371.244.643.65.666 1.226h.835c.024-.578.296-.983.668-1.227.03-.02.057-.044.088-.061l.005-.003a1.752 1.752 0 0 1 1.662 0l.003.002c.032.018.06.042.09.062.371.244.643.65.667 1.226h.743c-.019-2.309-3.229-3.115-4.343-1.128-.795-1.5-3.223-1.499-4.018 0-1.114-1.987-4.324-1.18-4.343 1.128h.744c.023-.578.295-.983.667-1.227"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M118.76 175.81a1.751 1.751 0 0 0-1.662-.002l-.005.003c-.031.018-.059.041-.089.061a1.59 1.59 0 0 0-.667 1.227h3.182a1.59 1.59 0 0 0-.667-1.226c-.03-.02-.058-.044-.09-.062a.005.005 0 0 0-.003-.001m4.017-.001a1.75 1.75 0 0 0-1.66-.001l-.007.003c-.03.018-.058.041-.088.061-.386.278-.647.72-.668 1.228h3.183a1.592 1.592 0 0 0-.667-1.227c-.03-.02-.058-.044-.09-.062l-.003-.002m4.017.001a1.75 1.75 0 0 0-1.66-.002l-.006.003c-.031.018-.058.041-.088.061a1.59 1.59 0 0 0-.668 1.227h3.182a1.592 1.592 0 0 0-.666-1.226c-.03-.02-.059-.044-.09-.062a.005.005 0 0 0-.003-.001"
      style="fill:#ffdc04;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M117.004 175.872c.03-.02.057-.043.088-.061l.005-.003a1.751 1.751 0 0 1 1.662 0l.003.002c.031.018.06.042.09.062.371.244.643.65.666 1.227h.835c.024-.578.296-.984.668-1.228.03-.02.057-.043.088-.06l.006-.004a1.75 1.75 0 0 1 1.661.001l.003.001c.032.018.06.042.09.062.372.245.643.65.667 1.227h.835c.023-.578.296-.983.667-1.227.03-.02.058-.044.089-.061l.005-.003a1.751 1.751 0 0 1 1.661 0l.003.002c.032.018.06.042.09.062.372.244.643.65.667 1.226h.744c-.02-2.309-3.23-3.115-4.344-1.128-.794-1.5-3.223-1.499-4.017 0-1.115-1.987-4.325-1.18-4.344 1.128h.744c.024-.578.296-.983.668-1.227"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M104.465 172.843a13.651 13.651 0 0 0-2.286-2.635 12.436 12.436 0 0 0-1.702-1.383c-2.722-1.74-6.02-2.454-9.217-2.143-3.729.433-7.295 2.266-9.615 5.192-1.1 1.445-1.945 3.184-2.422 5.223v.002h3.037a4.854 4.854 0 0 1 .063-1.72c.267-1.474 1.098-2.529 2.177-3.163a4.857 4.857 0 0 1 2.721-.816 4.856 4.856 0 0 1 2.724.818c1.079.635 1.91 1.69 2.176 3.165a4.85 4.85 0 0 1 .062 1.716h1.158a4.839 4.839 0 0 1 .063-1.72c.267-1.474 1.099-2.529 2.178-3.163a4.856 4.856 0 0 1 2.72-.816 4.857 4.857 0 0 1 2.724.818c1.079.635 1.91 1.69 2.176 3.164a4.85 4.85 0 0 1 .062 1.717h2.952a13.926 13.926 0 0 0-1.751-4.257"
      style="fill:#ffdc04;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M79.223 177.097c.477-2.039 1.321-3.778 2.422-5.223 2.364-3.102 5.92-4.832 9.615-5.192 3.196-.311 6.496.403 9.217 2.143 1.564 1 2.936 2.34 3.988 4.018a13.944 13.944 0 0 1 1.751 4.256h.757c-3.307-14.977-25.205-14.968-28.507 0h.757v-.002"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M102.485 175.593a4.352 4.352 0 0 0-1.847-2.74c-1.395-.827-3.273-.828-4.669-.002a4.35 4.35 0 0 0-1.848 2.739 5.31 5.31 0 0 0-.028 1.51h1.298a2.84 2.84 0 0 1 .003-1.074c.137-.899.63-1.54 1.277-1.926a2.85 2.85 0 0 1 1.632-.497 2.851 2.851 0 0 1 1.633.498c.648.386 1.14 1.028 1.276 1.927.065.345.073.706.002 1.071h1.298c.066-.55.048-1.05-.027-1.507"
      style="fill:#fff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M103.202 175.383a5.097 5.097 0 0 0-2.176-3.165 4.858 4.858 0 0 0-2.723-.818 4.857 4.857 0 0 0-2.721.816 5.099 5.099 0 0 0-2.178 3.164 4.848 4.848 0 0 0-.063 1.72h.752a5.31 5.31 0 0 1 .028-1.51c.212-1.278.921-2.19 1.848-2.74 1.396-.825 3.274-.824 4.67.003.926.549 1.635 1.462 1.846 2.74.076.457.093.956.027 1.507h.752a4.849 4.849 0 0 0-.062-1.717"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M100.476 176.637h-.744a1.531 1.531 0 0 0-.451-1.09 1.546 1.546 0 0 0-1.088-.452v-.745a2.284 2.284 0 0 1 2.283 2.287zm.736-.609a3.023 3.023 0 0 0-1.276-1.927 2.852 2.852 0 0 0-1.633-.497 2.849 2.849 0 0 0-1.632.496 3.023 3.023 0 0 0-1.277 1.926 2.855 2.855 0 0 0-.003 1.073h5.823a2.85 2.85 0 0 0-.002-1.07"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M91.404 175.592a4.354 4.354 0 0 0-1.847-2.74c-1.395-.826-3.273-.827-4.67 0a4.35 4.35 0 0 0-1.848 2.738 5.309 5.309 0 0 0-.028 1.51h1.299a2.838 2.838 0 0 1 .003-1.074c.137-.898.63-1.54 1.277-1.926a2.856 2.856 0 0 1 1.631-.496 2.856 2.856 0 0 1 1.634.498c.647.386 1.139 1.028 1.276 1.927.065.345.072.706.002 1.07h1.298c.065-.55.048-1.05-.028-1.506"
      style="fill:#fff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M92.12 175.383a5.099 5.099 0 0 0-2.175-3.165 4.856 4.856 0 0 0-2.724-.817 4.857 4.857 0 0 0-2.72.815 5.098 5.098 0 0 0-2.178 3.164 4.851 4.851 0 0 0-.064 1.719h.753a5.327 5.327 0 0 1 .027-1.51c.212-1.277.922-2.19 1.849-2.738 1.396-.826 3.274-.825 4.669.002.927.549 1.635 1.462 1.847 2.74.075.457.092.956.027 1.507h.752a4.847 4.847 0 0 0-.062-1.717"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M89.335 176.637h-.744a1.538 1.538 0 0 0-.94-1.42 1.53 1.53 0 0 0-.599-.122v-.745a2.284 2.284 0 0 1 2.283 2.287zm.796-.608a3.023 3.023 0 0 0-1.276-1.928 2.854 2.854 0 0 0-1.634-.497 2.848 2.848 0 0 0-1.631.496 3.02 3.02 0 0 0-1.277 1.926 2.848 2.848 0 0 0-.003 1.073h5.823a2.85 2.85 0 0 0-.002-1.07"
      style="fill:#000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
    <path
      d="M100.2 175.548a2.284 2.284 0 0 0-2.007-1.198v.745a1.524 1.524 0 0 1 1.088.452 1.548 1.548 0 0 1 .45 1.09h.744a2.273 2.273 0 0 0-.275-1.09m-11.14.001a2.284 2.284 0 0 0-2.008-1.198v.745a1.526 1.526 0 0 1 1.088.452 1.547 1.547 0 0 1 .451 1.09h.744a2.273 2.273 0 0 0-.276-1.09"
      style="fill:#fff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.352778"
      transform="translate(-57.978 -165.87)"
    />
  </svg>
</template>
